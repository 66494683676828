<template>
  <authorization-guard
    permission="crm.company.index"
    silent
  >
    <div class="card shadow-none bg-transparent border-secondary">
      <div class="card-body">
        <h4 class="card-title">
          {{ $t('global.title.filtering') }}
        </h4>
        <b-row>

          <b-col cols="4">
            <b-form-group
              :label="$t('crm.companies.field.region')"
            >
              <core-branch-picker
                multiple
                v-model="filters.branch_ids"
              />
            </b-form-group>
          </b-col>

          <b-col cols="4">
            <b-form-group
              :label="$t('crm.companies.field.status')"
            >
              <crm-status-picker
                multiple
                v-model="filters.status_ids"
              />
            </b-form-group>
          </b-col>

          <b-col cols="4">
            <b-form-group
              :label="$t('crm.companies.field.industry')"
            >
              <crm-industry-picker
                multiple
                v-model="filters.industry_ids"
              />
            </b-form-group>
          </b-col>

          <b-col cols="4">
            <b-form-group
              :label="$t('crm.companies.field.services')"
            >
              <crm-service-picker
                multiple
                v-model="filters.service_ids"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <a href="#" @click="clearAllFilters">
          {{ $t('global.table.clearAllFilters') }}
        </a>
      </div>
    </div>
  </authorization-guard>
</template>

<script>
import { BCol, BRow, BFormGroup } from 'bootstrap-vue';
import { CRMProviders } from '@/hooks/tables';
import AuthorizationGuard from '@/components/ui/AuthorizationGuard.vue';
import CoreBranchPicker from '@/components/module/core/CoreBranchPicker.vue';
import CrmStatusPicker from '@/components/module/crm/CrmStatusPicker.vue';
import CrmIndustryPicker from '@/components/module/crm/CrmIndustryPicker.vue';
import CrmServicePicker from '@/components/module/crm/CrmServicePicker.vue';

export default {
  setup() {
    const { table, filters, clearAllFilters } = CRMProviders.value;

    return {
      filters,
      clearAllFilters,
      table,
    };
  },
  components: {
    BCol,
    BRow,
    BFormGroup,

    AuthorizationGuard,
    CoreBranchPicker,
    CrmStatusPicker,
    CrmIndustryPicker,
    CrmServicePicker,
  },
};
</script>
