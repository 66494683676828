<template>
  <div class="crm-providers-list">
    <b-card>
      <provider-list-filtration />

      <div class="buttons">
        <div class="group">
          <vs-button
            v-b-modal.modal-crm-company-add
            vs-variant="primary"
            v-can:store="'crm.company'"
          >
            + {{ $t('crm.providers.button.add') }}
          </vs-button>
        </div>
      </div>
      <br>

      <company-list-table
        company-type="providers"
        detailsRouteName="crm.providers.details"
      />

      <company-modal-add type="provider" :table="CRMProviders"/>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import ProviderListFiltration from '@/components/views/crm/providers/ProviderListFiltration.vue';
import CompanyListTable from '@/components/views/crm/company/CompanyListTable.vue';
import CompanyModalAdd from '@/components/views/crm/company/modals/CompanyModalAdd.vue';
import { CRMProviders } from '@/hooks/tables';

export default {
  components: {
    BCard,

    VsButton,
    ProviderListFiltration,
    CompanyListTable,
    CompanyModalAdd,
  },
  setup() {
    return { CRMProviders };
  },
};
</script>
